export default {
  state: {
    singular: "status",
    plural: "statuses",
  },
  standardized_area: {
    singular: "square footage",
    plural: "square footages",
  },
  horizontal_alignment: {
    singular: "floor level horizontal alignment",
    plural: "floor level horizontal alignments",
  },
  ContentLocation: {
    singular: "physical location",
    plural: "physical locations",
  },
  Property: {
    singular: "property",
    plural: "properties",
  },
  LandCovering: {
    singular: "building or surface structure",
    plural: "buildings and surface structures",
  },
  LandCoveringLevel: {
    singular: "floor level",
    plural: "floor levels",
  },
  LandCoveringGrouping: {
    singular: "combined building",
    plural: "combined buildings",
  },
  PropertyRight: {
    singular: "property right",
    plural: "property rights",
  },
  FloorArea: {
    singular: "floor area",
    plural: "floor areas",
  },
  FloorAreaLayout: {
    singular: "floor layout",
    plural: "floor layouts",
  },
  PropertyDiagramVisualOrdering: {
    singular: "Visual Order of Diagram Content",
    plural: "Visual Order of Diagram Content",
  },
};
